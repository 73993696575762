//
//  src/layouts/depict/booking/scheduled/Scheduled.js
//
import * as React    from "react";
import { useParams } from 'react-router-dom';
import Nav           from "../../../../components/nav/Nav"
import Booked        from "../../../../components/booked/Booked"
import Footer        from "../../../../components/footer/Footer"
import './Scheduled.css'


//
//  booking scheduled
//
export default function BookingScheduled() {

    const { event } = useParams();

    return (
        <div id="booking">
           <Nav                  />
           <Booked event={event} />
           <Footer               />
        </div>
    );
}
