//
//  src/components/booked/Booked.js
//
import React           from 'react';
import { useLocation } from 'react-router-dom';
import './Booked.css'; 


//
//  booked
//
const Booked = () => {

    const { search } = useLocation();
    const params = new URLSearchParams(search);  

    const assignedTo      = params.get('assigned_to');
    const eventTypeName   = params.get('event_type_name');
    const eventStartTime  = params.get('event_start_time');
    const eventEndTime    = params.get('event_end_time');
    const inviteeFullName = params.get('invitee_full_name');
    const inviteeEmail    = params.get('invitee_email');
    const answer1         = params.get('answer_1');

    const eventMessage = eventTypeName === 'Consultation'
                       ? "To ensure we make the most of our time together, please come prepared with a few things in mind. First, have an idea of where you'd like your tattoo to be placed on your body, as well as the size you're envisioning. This will help us plan the design to fit perfectly with your body.<br /><br />Next, think about the style or vibe you're aiming for. Whether it's traditional, realism, geometric, or something else, having a style in mind will help guide the design process.<br /><br />Finally, please bring any reference material that can help convey your vision. This could include images of tattoos you like, artwork, or anything else that inspires you. The more reference material you bring, the better we can collaborate to create a design that’s perfect for you.<br /><br />We’re excited to work with you and bring your tattoo idea to life!"
                       : eventTypeName === 'Small Tattoo'
                       ? "To ensure your tattoo session goes as smoothly and comfortably as possible, there are a few important things to keep in mind. First, make sure you get enough rest the night before your session. Being well-rested will help you stay relaxed and better handle the tattoo process. Additionally, avoid getting sunburned or overexposed to the sun in the days leading up to your appointment. Sunburn or irritated skin can make the tattoo process uncomfortable and affect the healing.<br /><br />It's also important to shave the area where the tattoo will go. A clean, smooth surface will help your artist work more efficiently and provide the best results. Before the session, make sure to eat a light meal and stay hydrated. This will help keep your energy levels up during the tattoo and prevent you from feeling lightheaded or fatigued.<br /><br />If you're booked for a longer session, be sure to bring snacks and drinks to keep you energized throughout. If you are on any medication or have any medical conditions, please consult with your healthcare provider before the appointment to ensure it's safe for you to get tattooed.<br /><br />Lastly, avoid using numbing products or creams before your session. These products can interfere with the tattoo process and the quality of the work. Following these guidelines will help ensure a smooth, comfortable experience as we work together to create your new tattoo!"
                       : eventTypeName === 'Half Day Sitting'
                       ? "To ensure your tattoo session goes as smoothly and comfortably as possible, there are a few important things to keep in mind. First, make sure you get enough rest the night before your session. Being well-rested will help you stay relaxed and better handle the tattoo process. Additionally, avoid getting sunburned or overexposed to the sun in the days leading up to your appointment. Sunburn or irritated skin can make the tattoo process uncomfortable and affect the healing.<br /><br />It's also important to shave the area where the tattoo will go. A clean, smooth surface will help your artist work more efficiently and provide the best results. Before the session, make sure to eat a light meal and stay hydrated. This will help keep your energy levels up during the tattoo and prevent you from feeling lightheaded or fatigued.<br /><br />If you're booked for a longer session, be sure to bring snacks and drinks to keep you energized throughout. If you are on any medication or have any medical conditions, please consult with your healthcare provider before the appointment to ensure it's safe for you to get tattooed.<br /><br />Lastly, avoid using numbing products or creams before your session. These products can interfere with the tattoo process and the quality of the work. Following these guidelines will help ensure a smooth, comfortable experience as we work together to create your new tattoo!"
                       : eventTypeName === 'Full Day Sitting'
                       ? "To ensure your tattoo session goes as smoothly and comfortably as possible, there are a few important things to keep in mind. First, make sure you get enough rest the night before your session. Being well-rested will help you stay relaxed and better handle the tattoo process. Additionally, avoid getting sunburned or overexposed to the sun in the days leading up to your appointment. Sunburn or irritated skin can make the tattoo process uncomfortable and affect the healing.<br /><br />It's also important to shave the area where the tattoo will go. A clean, smooth surface will help your artist work more efficiently and provide the best results. Before the session, make sure to eat a light meal and stay hydrated. This will help keep your energy levels up during the tattoo and prevent you from feeling lightheaded or fatigued.<br /><br />If you're booked for a longer session, be sure to bring snacks and drinks to keep you energized throughout. If you are on any medication or have any medical conditions, please consult with your healthcare provider before the appointment to ensure it's safe for you to get tattooed.<br /><br />Lastly, avoid using numbing products or creams before your session. These products can interfere with the tattoo process and the quality of the work. Following these guidelines will help ensure a smooth, comfortable experience as we work together to create your new tattoo!"            
                       : 'Oops...';

    return (
        <div className="booked valign-wrapper">
            <div className="container">
                <div className="row">
                    <form className="col s12">
                        <div className="row">
                            <div className="center-align">
                                <h1>{eventTypeName} Confirmed</h1>
                            </div>
                            <div className="snippet">
                                Thank you for booking your session! If you have any questions or need to discuss any details in the meantime, please don't hesitate to reach out. You can contact us via the form on this website, send an email, or message on Instagram.
                            </div>                             
                        </div>
                        <div className="row">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Artist Name</th>
                                        <td>{assignedTo}</td>
                                    </tr>
                                    <tr>
                                        <th>Artist Email</th>
                                        <td><a href="mailto:jeff@jefftattooer.com">jeff@jefftattooer.com</a></td>
                                    </tr>
                                    <tr>
                                        <th>Artist Instagram</th>
                                        <td>
                                            <a href="https://instagram.com/jeff_tattooer" target="_blank" rel="noopener noreferrer">
                                                @jeff_tattooer
                                            </a>
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <th>Event Type</th>
                                        <td>{eventTypeName}</td>
                                    </tr>
                                    <tr>
                                        <th>Event Start Time</th>
                                        <td>
                                            {new Date(eventStartTime).toLocaleDateString('en-AU', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Event End Time</th>
                                        <td>
                                            {new Date(eventEndTime).toLocaleString('en-AU', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Contact Name</th>
                                        <td>{inviteeFullName}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact Email</th>
                                        <td>{inviteeEmail}</td>
                                    </tr>
                                    <tr>
                                        <th>Notes</th>
                                        <td>{answer1}</td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                        <div className="row">
                            <div className="center-align">
                                <div className="snippet" dangerouslySetInnerHTML={{ __html: eventMessage }}>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

};

export default Booked;
