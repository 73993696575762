//
//  src/layouts/depict/booking/Booking.js
//
import * as React from "react";
import Nav        from "../../../components/nav/Nav"
import Calendly   from "../../../components/calendly/CalendlyInline"
import Footer     from "../../../components/footer/Footer"
import './Booking.css'


//
//  booking
//
export default function Booking() {
    return (
        <div id="booking">
           <Nav        />
           <Calendly   />
           <Footer     />
        </div>
    );
}
