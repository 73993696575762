//
//  src/components/loading/Loading.js
//
import React from "react";
import "materialize-css/dist/css/materialize.min.css";
import './Loading.css'
  

//
//  loading
//
export default class Loading extends React.Component {

    render() {
        return (
            <div className="loading animate">
                <div className="bar"></div>
            </div>
        );
    }

}
