//
//  src/components/calendly/CalendlyInline.js
//
import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCalendar
} from '@fortawesome/free-solid-svg-icons'
import './CalendlyInline.css'


//
//  calendly
//
const Calendly = () => {

    useCalendlyEventListener({
        onEventTypeViewed: (e) => {
            const snippet = document.querySelector(".snippet");
            if (snippet) {
                snippet.innerHTML = "Have a glance at the event description and find a time that works best for you—then go ahead and secure your spot.";
            }
        },
        onDateAndTimeSelected: (e) => {
            const snippet = document.querySelector(".snippet");
            if (snippet) {
                snippet.innerHTML = "Ready to lock in your tattoo? Just enter your details, chip in a small deposit if needed, and you're all set—check out the pricing disclaimer at the bottom for the scoop.";
            }
        },
        onPageHeightResize: (e) => {
            const { height } = e.data.payload;
            const container = document.querySelector(".calendly-inline-widget");
            if (container) {
                container.style.height = height;
            }
        },
        onProfilePageViewed: () => {
            const placeholder = document.querySelector(".calendly .placeholder");
            placeholder.style.display = "none";
        }
    });

    return (
        <>
            <div className="calendly valign-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="center-align">
                            <h1>Make a Booking</h1>
                            <div className="snippet">
                                Have a design in mind? Book your session now and let’s make it happen! If you're still exploring designs, book a free consultation to go over your ideas and pricing.
                            </div>
                        </div>
                    </div>
                    <div className="placeholder row">
                        <div class="icon">
                            <FontAwesomeIcon className="fa" icon={faCalendar} />
                        </div>
                    </div>
                    <div className="row">
                        <InlineWidget
                            resize="true"
                            styles={{}}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: 'rgba(255, 119, 0, 0.9)',
                                textColor: 'rgba(255, 119, 0, 0.9)'
                            }}
                            textColor="#ffffff"
                            color="rgba(255, 119, 0, 0.9)"
                            url={`https://calendly.com/${process.env.REACT_APP_CALENDLY_USERNAME}?hide_gdpr_banner=1&hide_landing_page_details=1`}
                        />
                    </div>
                    <div className="row">
                        <div className="disclaimer">
                            Prices displayed during the booking process represent deposits only. Because every tattoo is unique, each piece is priced individually based on factors such as design complexity, time, planning, number of sessions, and body area. For an accurate estimate, please schedule a consultation where we can discuss your specific design and details before booking your session.
                        </div>
                    </div>
                    <div className="row">
                        <p className="waiver">
                            By submitting this form, you confirm that you have read, understood, and agreed to our <a href="/legal/terms-and-conditions/" alt="terms and conditions">Terms and Conditions</a> and <a href="/legal/privacy-policy/" alt="privacy policy">Privacy Policy</a>.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

//
//  export
//
export default Calendly;
