//
//  src/components/enquire/EnquireForm.js
//
import EnquireReferrer from './EnquireReferrer';
import React, { useEffect, useState } from "react";
import { useNavigate }   from "react-router-dom";
import { useDropzone }   from 'react-dropzone';
import { useMediaQuery } from 'react-responsive';
import M from "materialize-css/dist/js/materialize.min.js";
import './Enquire.css';


//
//  enquire form var[s]
//
const MAX_FILE_SIZE = 5;
const MAX_FILES = 6;


//
//  enquire form dropzone
//
function MultiFileDropzone({ onFilesChange }) {

    const [error, setError] = useState(null);

    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles: MAX_FILES,
        maxSize: MAX_FILE_SIZE * 1024 * 1024,
        multiple: true,
        onDrop: acceptedFiles => {
            const mapped = acceptedFiles.map(file =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            );
            onFilesChange(prev => [...prev, ...mapped]);
        },
        onDropAccepted: () => setError([]),
        onDropRejected: (fileRejections) => {
            fileRejections.forEach(({ file, errors }) => {
                errors.forEach(err => {
                    if (err.code === 'file-too-large') {
                        setError(`File is too large — max size is ${MAX_FILE_SIZE} MB.`);
                    } else {
                        setError(`${err.message}`);
                    }
                });
            });
        }
    });

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Drop images here…</p>
            ) : (
                <>
                    {isMobile ? (
                        <p>Tap to attach images</p>
                    ) : (
                        <p>Drag & drop images here, or click to browse your files</p>
                    )}
                </>
            )}
            {error}
        </div>
    );

}


//
//  enquire form
//
export default function EnquireForm(props) {

    const navigate = useNavigate();

    const [data, setData] = useState({
        'from_name': "",
        'from_name_first': "",
        'from_name_last': "",
        'from_email': "",
        'message': "",
        'referrer_code': "",
        'type': "form.contact"
    });

    const [isDisabled, setDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isReferred, setReferred] = useState(true);

    const [files, setFiles] = useState([]);

    const referrers = ["L337HACKER"];

    const handleFilesChange = (newFiles) => {
        if (files.length < MAX_FILES) {
            setFiles(newFiles);
        }
    };

    const removeFile = (index) => {
        setFiles(prev => {
            const updated = prev.filter((_, i) => i !== index);
            return updated;
        });
    };

    const send = (e) => {

        // prevent default
        e.preventDefault();

        // disable form
        setDisabled(true);

        // validate email
        if (!validate(data.from_email)) {
            setDisabled(false);
            return false;
        }

        // build form Data
        const formData = new FormData();

        // build form data append values
        Object.entries(data).forEach(([key, value]) => {
            if (value) formData.append(key, value);
        });

        // build form data append files
        files.forEach(file => formData.append('attachments[]', file));

        // set loading
        setLoading(true);

        // send request
        fetch(`${process.env.REACT_APP_API_BASE}/guest/contact`, {
            method: 'POST',
            body: formData,
        }).then(response => response.json())
            .then(sendSuccess)
            .catch(sendError);

    };

    const sendError = (error) => {
        console.error(error)
        setData(data);
        setDisabled(true)
        setLoading(false);
        navigate("/contact/error/");
        M.toast({ html: 'Network error. Please try again.', classes: 'red' });
    }

    const sendSuccess = (data) => {
        data = {}
        setData(data);
        setDisabled(false)
        setLoading(false);
        navigate("/contact/sent/");
        M.toast({ html: 'Thank you for your enquiry! We will get back to you shortly.', classes: 'green' });
    }

    const set = (key, value) => {

        // set data[key] value
        data[key] = value

        // set data[from_name]
        if (data['from_name_first'] === undefined) {
            data['from_name_first'] = ""
        }
        if (data['from_name_last'] === undefined) {
            data['from_name_last'] = ""
        }
        data['from_name'] = data['from_name_first'] + " " + data['from_name_last']

        // set file
        if (key === 'file') {
            data.file = value;
        }

        // set message[referrer]
        if (data['message']) {
            let referrer_code = referrers.find(item => data['message'].toLocaleLowerCase().includes(item.toLowerCase()));
            if (referrer_code) {
                data['referrer_code'] = referrer_code;
                setReferred(true);
            } else {
                data['referrer_code'] = '';
                setReferred(false);
            }
        } else {
            data['referrer_code'] = '';
            setReferred(false);
        }

        // validate
        if (validate(data['from_email'])) {
            setDisabled(false)
        }

        // set data
        setData(data);

    }

    const submit = (event) => {
        event.preventDefault();
    };

    const validate = (email) => {
        return email.match(
            // eslint-disable-next-line
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    useEffect(() => {

        // materialize
        M.updateTextFields()

    });

    useEffect(() => {
        return () => files.forEach(f => URL.revokeObjectURL(f.preview));
    }, [files]);

    return (
        <div className="enquire valign-wrapper">
            <div className="enquire-form">
                <div className="row">
                    <form className="col s12" onSubmit={submit}>
                        <div className="row">
                            <div className="">
                                <h1>Contact Our Team</h1>
                                <div className="action-text">
                                    We believe every question matters—no question is too small. Fill out the form below and let’s start the conversation! We're ready to help you explore your ideas and bring your vision to life.
                                </div>
                            </div>
                        </div>
                        <br /><br /><br />
                        <div className="row">
                            <div className="input-field col s6">
                                <input id="first_name" onChange={(e) => { set('from_name_first', e.target.value) }} placeholder="First Name" type="text" className="validate" />
                                <label htmlFor="first_name">First Name</label>
                            </div>
                            <div className="input-field col s6">
                                <input id="last_name" onChange={(e) => { set('from_name_last', e.target.value) }} placeholder="Last Name" type="text" className="validate" />
                                <label htmlFor="last_name">Last Name</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="email" onChange={(e) => { set('from_email', e.target.value) }} placeholder="E-Mail" type="email" className="validate" required />
                                <label htmlFor="email">Email</label>
                            </div>
                        </div>
                        {isReferred && data['referrer_code'] !== "" ? (
                            <EnquireReferrer code={data['referrer_code']} />
                        ) : (
                            <></>
                        )}
                        <div className="row">
                            <div className="input-field col s12">
                                <textarea id="message" onChange={(e) => { set('message', e.target.value) }} placeholder="Message" className="materialize-textarea">
                                </textarea>
                                <label htmlFor="message">Message</label>
                            </div>
                        </div>
                        <div className="row">
                            {files.length < MAX_FILES && (
                                <MultiFileDropzone onFilesChange={handleFilesChange} />
                            )}
                            <div className="thumbnails">
                                {files.map((file, i) => (
                                    <div key={i} className="thumb">
                                        <button
                                            type="button"
                                            className="remove-btn"
                                            onClick={() => removeFile(i)}
                                        >
                                            ×
                                        </button>
                                        <img src={file.preview} alt={file.name} />
                                        <p>{file.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="row">
                            <button id="submit" className="btn waves-effect waves-light orange" onClick={send} name="action" disabled={isDisabled} >
                                {isLoading ? 'Processing...' : 'Send Message'}
                            </button>
                        </div>
                        <br />
                        <div className="row">
                            <p className="waiver">
                                By submitting this form, you confirm that you have read, understood, and agreed to our <a href="/legal/terms-and-conditions/" alt="terms and conditions">Terms and Conditions</a> and <a href="/legal/privacy-policy/" alt="privacy policy">Privacy Policy</a>.
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
