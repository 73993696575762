//
//  src/layouts/depict/legal/Privacy.js
//
import * as React from "react";
import Nav from "../../../../components/nav/Nav"
import Footer from "../../../../components/footer/Footer"
import './Privacy.css'


//
//  effective date
//
const EFFECTIVE_DATE = '01/04/2025'


//
//  legal privacy
//
export default function LegalPrivacy() {
    return (
        <>
            <Nav />
            <div id="privacy">
                <div className="container">
                    <main>
                        <h1>Privacy Policy</h1>
                        <p><strong>Effective Date:</strong> {EFFECTIVE_DATE}</p>
                        <section>
                            <p>
                                This Privacy Policy explains how Jeff Tattooer collects, uses, and protects your personal information when you visit our website. It applies to all data you share with us – whether through contact forms, booking requests, media uploads, or any other method of communication.
                            </p>
                        </section>

                        <section>
                            <h2>1. Information We Collect</h2>
                            <p>
                                We collect a variety of information to help us provide a personalized and efficient service. This includes:
                            </p>
                            <ul>
                                <li>
                                    <strong>Personal Information:</strong> Your name, email address, phone number, and other details you provide when contacting or booking with us.
                                </li>
                                <li>
                                    <strong>Media Submissions:</strong> Photos, videos, or other creative content uploaded to showcase tattoo ideas, designs, or inquiries.
                                </li>
                                <li>
                                    <strong>Usage Data:</strong> Information automatically collected through your interactions with our website, such as IP address, browser type, and navigation details.
                                </li>
                            </ul>
                        </section>

                        <section>
                            <h2>2. How We Use Your Information</h2>
                            <p>
                                Your information is essential for us to manage inquiries, process bookings, and improve our services. Specifically, we use your data to:
                            </p>
                            <ul>
                                <li>
                                    <strong>Manage Communications:</strong> Respond to your inquiries and process booking requests effectively.
                                </li>
                                <li>
                                    <strong>Enhance Our Services:</strong> Use data for internal analytics to improve our website's functionality and overall customer experience.
                                </li>
                                <li>
                                    <strong>Marketing and Promotions:</strong> With your consent, display and share media uploads in our portfolios, advertisements, social media, and other promotional materials.
                                </li>
                            </ul>
                            <p>
                                By submitting media, you grant Jeff Tattooer a non-exclusive, royalty-free license to use your content solely in connection with creating tattoos, designs, or derivative works based on the content you upload.
                            </p>
                        </section>

                        <section>
                            <h2>3. Cookies and Tracking Technologies</h2>
                            <p>
                                Our website utilizes cookies and similar tracking technologies to enhance your browsing experience and analyze site traffic. You have the option to adjust your browser settings to manage or disable cookies.
                            </p>
                        </section>

                        <section>
                            <h2>4. Data Security</h2>
                            <p>
                                We implement appropriate security measures to protect your personal information and media submissions from unauthorized access, alteration, or disclosure. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.
                            </p>
                        </section>

                        <section>
                            <h2>5. Third-Party Services</h2>
                            <p>
                                We may share your information with trusted third-party providers who assist us in operating our website and conducting our business. All third-party partners are required to maintain the confidentiality of your data and adhere to strict data protection standards.
                            </p>
                        </section>

                        <section>
                            <h2>6. Your Responsibilities and Legal Accountability</h2>
                            <p>
                                When submitting information or media to our website, you confirm that:
                            </p>
                            <ul>
                                <li>
                                    You have the necessary rights or permissions for the content you provide.
                                </li>
                                <li>
                                    Your submission does not infringe on any third-party rights, including copyrights or privacy rights.
                                </li>
                                <li>
                                    You assume full responsibility for the content you upload, and you agree to indemnify Jeff Tattooer against any claims arising from your submissions.
                                </li>
                            </ul>
                        </section>

                        <section>
                            <h2>7. Changes to This Privacy Policy</h2>
                            <p>
                                Jeff Tattooer reserves the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and the "Effective Date" will be updated accordingly. We encourage you to review this policy periodically to stay informed about how your information is protected.
                            </p>
                        </section>

                        <section>
                            <h2>8. Contact Us</h2>
                            <p>
                                If you have any questions, concerns, or requests regarding this Privacy Policy or the way we handle your information, please contact us at:
                            </p>
                            <p>
                                <strong>Email:</strong> <a href="mailto:jeff@jefftattooer.com" aria-label="Send email to Jeff Tattooer">jeff@jefftattooer.com</a>
                            </p>
                        </section>

                        <section>
                            <p>
                                This Privacy Policy is a commitment by Jeff Tattooer to protect your privacy while providing exceptional tattoo services. Thank you for placing your trust in us.
                            </p>
                        </section>
                    </main>
                </div>
            </div>
            <Footer />
        </>
    );
}
