//
//  src/index.js
//
import React, { useState, useEffect } from "react";
import ReactDOM                       from "react-dom/client";
import { Helmet }                     from "react-helmet";
import { BrowserRouter }              from "react-router-dom";
import Index                          from "./layouts/Index";


//
//  app
//
function App() {

    const [viewportContent, setViewportContent] = useState(
        "width=device-width, initial-scale=1.0, maximum-scale=1.0"
    );

    const updateViewport = () => {
        if (window.innerWidth < 450) {
            setViewportContent("width=device-width, initial-scale=0.75")
        } else if (window.innerWidth > 600) {
            setViewportContent("width=device-width, initial-scale=1.0")
        }
    };

    useEffect(() => {
        updateViewport();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateViewport);
        return () => window.addEventListener("resize", updateViewport);
    });    

    return (
        <>
            <Helmet>
                <meta name="viewport" content={viewportContent} />
            </Helmet>
            <BrowserRouter>
                <Index />
            </BrowserRouter>
        </>
    );

}


//
//  root
//
const root = ReactDOM.createRoot(document.getElementById("root"));


//
//  root render
//
root.render(<App />);
