//
//  src/components/calendly/CalendlyBadge.js
//
import * as React from "react";
import './CalendlyBadge.css'


//
//  calendly
//
const Calendly = () => {
    return (
        <>
            <a href="/bookings/" className="calendly-badge">                
                <div className="calendly-badge-content">
                    Book Now
                </div>
            </a>
        </>
    );
}

//
//  export
//
export default Calendly;
