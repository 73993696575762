//
//  src/layouts/depict/legal/terms/Terms.js
//
import * as React from "react";
import Nav from "../../../../components/nav/Nav"
import Footer from "../../../../components/footer/Footer"
import './Terms.css'


//
//  effective date
//
const EFFECTIVE_DATE = '01/04/2025'


//
//  legal terms
//
export default function LegalTerms() {
    return (
        <>
            <Nav />
            <div id="terms">
                <div className="container">
                    <main>
                        <h1>Terms and Conditions</h1>
                        <p><strong>Effective Date:</strong> {EFFECTIVE_DATE}</p>
                        <section>
                            <p>
                                Welcome to Jeff Tattooer! These Terms and Conditions govern the use of the "Upload Photo/Video" feature on our website, where customers can submit photos, videos, or other materials to communicate their tattoo inquiries or share their experiences with us. By uploading any media to our website, you agree to abide by these Terms and Conditions.
                            </p>
                        </section>

                        <section>
                            <h2>1. Acceptance of Terms</h2>
                            <p>
                                By uploading any content—including, but not limited to, photos, videos, or any other form of media ("Content")—or by using the Jeff Tattooer website and its associated features, you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please refrain from uploading any content or using the website.
                            </p>
                        </section>

                        <section>
                            <h2>2. Ownership and License Grant</h2>
                            <p>
                                You retain ownership of the content you submit. By uploading your content, you grant Jeff Tattooer a limited, non-exclusive, non-transferable license to use, modify, reproduce, display, distribute, and create derivative works solely for the purpose of tattoo-related content. This license does not authorize the use of your content for marketing, advertising, or promotional materials unless we obtain your explicit permission. Any such additional use will require prior, express consent from you, as well as any necessary permissions from the owner, artist, or other relevant parties.
                            </p>
                        </section>

                        <section>
                            <h2>3. No Legal Accountability for Submitted Media</h2>
                            <p>
                                You are solely responsible for the Media you upload. By submitting Media to Jeff Tattooer, you agree to:
                            </p>
                            <ul>
                                <li>Ensure that the Media is owned by you or that you have the necessary rights and permissions to grant us the usage rights outlined above.</li>
                                <li>Confirm that your Media does not violate the intellectual property rights or privacy rights of any third parties.</li>
                                <li>Assume responsibility for any legal consequences that may arise from the use of your Media, including any claims of copyright infringement, defamation, privacy violations, or other legal disputes.</li>
                            </ul>
                            <p>
                                You agree to indemnify and hold harmless Jeff Tattooer, its employees, agents, and affiliates, from any claims, damages, or liabilities arising from the use of your submitted Media.
                            </p>
                        </section>

                        <section>
                            <h2>4. Content Restrictions</h2>
                            <p>
                                By using the website and any of its features—including but not limited to content uploads—you agree not to submit any content that:
                            </p>
                            <ul>
                                <li>Infringes upon the copyright, trademark, or intellectual property rights of others.</li>
                                <li>Contains defamatory, harmful, or offensive material, including but not limited to hate speech, graphic violence, or pornography.</li>
                                <li>Violates the privacy or personal rights of others, including unauthorized use of images, videos, or other content involving third parties.</li>
                                <li>Is otherwise illegal, unlawful, or prohibited by these Terms and Conditions.</li>
                            </ul>
                        </section>

                        <section>
                            <h2>5. Data Security and Privacy</h2>
                            <p>
                                While we take reasonable precautions to protect the security of your data, please note that no method of data transmission over the internet is completely secure. You understand that Jeff Tattooer cannot guarantee the absolute security of the content you upload or information you share.
                            </p>
                        </section>

                        <section>
                            <h2>6. Right to Remove or Modify Media</h2>
                            <p>
                                Jeff Tattooer reserves the right to remove or modify any Media that it deems, in its sole discretion, to be inappropriate, offensive, or in violation of these Terms and Conditions. If you wish to request the removal of your Media from our website or promotional materials, you may contact us at <a href="mailto:jeff@jefftattooer.com" aria-label="Send email to Jeff Tattooer">jeff@jefftattooer.com</a>, and we will make reasonable efforts to comply.
                            </p>
                        </section>

                        <section>
                            <h2>7. Changes to These Terms and Conditions</h2>
                            <p>
                                Jeff Tattooer reserves the right to modify or update these Terms and Conditions at any time. Any changes will be communicated via the website, and the "Effective Date" will be updated. It is your responsibility to review these terms periodically to stay informed about any changes.
                            </p>
                        </section>

                        <section>
                            <h2>8. Limitation of Liability</h2>
                            <p>
                                To the fullest extent permitted by law, Jeff Tattooer shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from or in connection with the use of the website, its features, or any content submitted.
                            </p>
                        </section>

                        <section>
                            <h2>9. Governing Law</h2>
                            <p>
                            These Terms and Conditions are governed by and construed in accordance with the laws of Australia, without regard to its conflict of law principles. Any disputes arising out of these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in Australia.
                            </p>
                        </section>

                        <section>
                            <h2>10. Contact Information</h2>
                            <p>
                                If you have any questions or concerns about these Terms and Conditions, or if you wish to request the removal of your uploaded Media, please contact us at:
                            </p>
                            <p>
                                <strong>Email:</strong> <a href="mailto:jeff@jefftattooer.com" aria-label="Send email to Jeff Tattooer">jeff@jefftattooer.com</a>
                            </p>
                        </section>
                    </main>
                </div>
            </div>
            <Footer />
        </>
    );
}
