//
//  src/layouts/depict/Depict.js
//
import * as React           from "react";
import { Routes, Route }    from "react-router-dom";
import Home                 from "./home/Home";
import Booking              from "./booking/Booking";
import BookingScheduled     from "./booking/schedueled/Scheduled";
import Contact              from "./contact/Contact";
import Gallery              from "./gallery/Gallery";
import Location             from "./location/Location";
import LegalPrivacy         from "./legal/privacy/Privacy";
import LegalTerms           from "./legal/terms/Terms";
import MarketingUnsubscribe from "./marketing/unsubscribe/Unsubscribe"
import Works                from "./works/Works";
import "materialize-css/dist/css/materialize.min.css";


//
//  depict
//
export default function Depict() {
    return (
        <>
            <Routes>
                <Route path="/"                             element={ <Home                 /> } />
                <Route path="/booking/*"                    element={ <Booking              /> } />
                <Route path="/booking/:event/scheduled/"    element={ <BookingScheduled     /> } />
                <Route path="/bookings/*"                   element={ <Booking              /> } />
                <Route path="/contact/*"                    element={ <Contact              /> } />
                <Route path="/gallery/*"                    element={ <Gallery              /> } />
                <Route path="/legal/privacy-policy/*"       element={ <LegalPrivacy         /> } />
                <Route path="/legal/terms-and-conditions/*" element={ <LegalTerms           /> } />
                <Route path="/location/*"                   element={ <Location             /> } />
                <Route path="/marketing/unsubscribe/*"      element={ <MarketingUnsubscribe /> } />
                <Route path="/works/"                       element={ <Works                /> } />
            </Routes>
        </>
    );
}
