//
//  src/components/sliders/Slider0.js
//
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay  from 'react-awesome-slider/dist/autoplay';
import React         from "react";
import 'react-awesome-slider/dist/styles.css';
import './Slider.css';


//
//  ink[s]
//
import Ink1  from "../../images/sliders/1/1 chest four horsemen apocalypse.1280x1707.webp"
import Ink2  from "../../images/sliders/1/2 demon horns color leg.1280x1507.webp"
import Ink3  from "../../images/sliders/1/3 leg color face black.1280x1600.webp"
import Ink4  from "../../images/sliders/1/4 lion back black white.1280x1674.webp"
import Ink5  from "../../images/sliders/1/5 lion leg black white.1280x1480.webp"
import Ink6  from "../../images/sliders/1/6 wolf triangles arm black white.1280x1707.webp"
import Ink7  from "../../images/sliders/2/1 arm black white warrior.1280x1707.webp"
import Ink8  from "../../images/sliders/2/2 hand black white woman.1280x1706.webp"
import Ink9  from "../../images/sliders/2/3 should black white eagle sword.1280x1707.webp"
import Ink10 from "../../images/sliders/2/4 should black white woman.1280x1707.webp"
import Ink11 from "../../images/sliders/2/5 should face demon armor sword.1280x1570.webp"
import Ink12 from "../../images/sliders/2/6 shoulder black white man.1280x1707.webp"


//
//  slider
//
const AutoplaySlider = withAutoplay(AwesomeSlider);
const AutoplaySlider0 = (
    <AutoplaySlider
        bullets={ false }
        organicArrows={ false }
        play={ true }
        interval={ 4000 }
    >
        <div><img src={Ink1}  alt="chest four horsemen apocalypse" /></div>
        <div><img src={Ink2}  alt="demon horns color leg" /></div>
        <div><img src={Ink3}  alt="leg color face black" /></div>
        <div><img src={Ink4}  alt="lion back black white" /></div>
        <div><img src={Ink5}  alt="lion leg black white" /></div>
        <div><img src={Ink6}  alt="triangles arm black white" /></div>
        <div><img src={Ink7}  alt="arm black white warrior" /></div>
        <div><img src={Ink8}  alt="hand black white woman" /></div>
        <div><img src={Ink9}  alt="black white eagle sword" /></div>
        <div><img src={Ink10} alt="should black white woman" /></div>
        <div><img src={Ink11} alt="face demon armor sword" /></div>
        <div><img src={Ink12} alt="shoulder black white man" /></div>     
    </AutoplaySlider>
)


//
//  slider
//
export default function Slider() {

    // return.
    return (
        <>
            <a href="/gallery/" alt="gallery">
                {AutoplaySlider0}
            </a>
        </>
    );

}
