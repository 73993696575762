//
//  src/components/videos/Video.js
//
import React from 'react';


//
//
//
import videoMp4    from '../../videos/black-grey-surrealism-girl-and-skull-tattoo/black-grey-surrealism-girl-and-skull-tattoo.mp4';
import videoWebm   from '../../videos/black-grey-surrealism-girl-and-skull-tattoo/black-grey-surrealism-girl-and-skull-tattoo.webm';
import posterImage from '../../videos/black-grey-surrealism-girl-and-skull-tattoo/black-grey-surrealism-girl-and-skull-tattoo.png';


//
//  video
//
export default function Video() {

  return (
    <video 
      autoPlay 
      loop 
      muted 
      playsInline
      poster={posterImage}
      style={{ width: '100%' }}
    >
      <source src={videoMp4} type="video/mp4" />
      <source src={videoWebm} type="video/webm" />
      Your browser does not support the video tag.
    </video>
  );
};
